// PricingPlanSection.js
import React from 'react';
import { motion } from 'framer-motion';
import './Pricing.css'

const PricingPlanSection = () => {
    const packages = [
        {
            id: 1,
            title: 'Silver Package',
            features: [
                'Basic Marketing Plan',
                'Email Support',
                'Social Media Integration',
                'Weekly Analytics Report',
            ],
        },
        {
            id: 2,
            title: 'Gold Package',
            features: [
                'Advanced Marketing Plan',
                'Priority Email Support',
                'Social Media and SEO Integration',
                'Daily Analytics Report',
                'Custom Campaigns',
            ],
        },
        {
            id: 3,
            title: 'Premium Package',
            features: [
                'Comprehensive Marketing Plan',
                '24/7 Phone Support',
                'Full Integration with all Platforms',
                'Real-Time Analytics',
                'Dedicated Account Manager',
                'Custom Campaigns with A/B Testing',
            ],
        },
    ];

    return (
        <section className="pricing-plan-section" id='price'>
            <div className="container">
                <motion.h2
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                >
                    Our Packages
                </motion.h2>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                    className="pricing-plans"
                >
                    {packages.map(pkg => (
                        <motion.div
                            key={pkg.id}
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8 }}
                            className="pricing-card"
                        >
                            <h3>{pkg.title}</h3>
                            <p className="price">{pkg.price}</p>
                            <ul>
                                {pkg.features.map((feature, index) => (
                                    <li key={index}>{feature}</li>
                                ))}
                            </ul>
                            <a href="#contact"><button>Contact Sales</button></a>
                        </motion.div>
                    ))}
                </motion.div>
            </div>
        </section>
    );
};

export default PricingPlanSection;
