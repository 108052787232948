import './App.css';
import AboutUsSection from './comps/about/About';
import HeroSection from './comps/banner/Banner';
import CaseStudySection from './comps/faq/Faq';
import ClientsSection from './comps/client/Client';
import ContactFormSection from './comps/contact/Contact';
import Footer from './comps/footer/Footer';
import Navbar from './comps/navbar/Navbar';
import PricingPlanSection from './comps/pricing/Pricing';
import OurServices from './comps/service/Service';
import NewsletterSection from './comps/newsletter/Newsletter';

function App() {
  return (
    <div className="App">
       <Navbar />
       <HeroSection />
       <OurServices />
       <AboutUsSection />
       <ClientsSection />
       <PricingPlanSection />
       <CaseStudySection />
       <ContactFormSection />
       <NewsletterSection />
       <Footer />
    </div>
  );
}

export default App;
