import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './Faq.css';

const CaseStudySection = () => {
    const [expandedId, setExpandedId] = useState(null);

    const caseStudies = [
        {
            id: 1,
            title: 'Q1',
            description: 'A comprehensive digital marketing strategy for a leading e-commerce brand.',
            details: 'Detailed information about Case Study 1.',
        },
        {
            id: 2,
            title: 'Q2',
            description: 'Increasing organic traffic through SEO optimization for a tech startup.',
            details: 'Detailed information about Case Study 2.',
        },
        {
            id: 3,
            title: 'Q3',
            description: 'Successful social media campaign for a lifestyle brand.',
            details: 'Detailed information about Case Study 3.',
        },
        // Add more case studies as needed
    ];

    const toggleExpand = id => {
        setExpandedId(expandedId === id ? null : id);
    };

    return (
        <section className="case-study-section" id='faq'>
            <div className="container">
                <motion.h2
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                >
                    FAQs
                </motion.h2>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                    className="case-studies"
                >
                    {caseStudies.map(caseStudy => (
                        <motion.div
                            key={caseStudy.id}
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8 }}
                            className={`case-study-card ${expandedId === caseStudy.id ? 'expanded' : ''}`}
                            onClick={() => toggleExpand(caseStudy.id)}
                        >
                            <div className="case-study-summary">
                                <h3 style={{margin:"7px"}}>{caseStudy.title}</h3>
                                <p>{caseStudy.description}</p>
                                <div className="toggle-arrow">
                                    {expandedId === caseStudy.id ? '−' : '+'}
                                </div>
                            </div>
                            {expandedId === caseStudy.id && (
                                <motion.div
                                    initial={{ height: 0, opacity: 0 }}
                                    animate={{ height: 'auto', opacity: 1 }}
                                    transition={{ duration: 0.5 }}
                                    className="case-study-details"
                                >
                                    <p>{caseStudy.details}</p>
                                </motion.div>
                            )}
                        </motion.div>
                    ))}
                </motion.div>
            </div>
        </section>
    );
};

export default CaseStudySection;
