// AboutUsSection.js
import React from 'react';
import { motion } from 'framer-motion';
import './About.css'

const AboutUsSection = () => {
    return (
        <section className="about-us-section" id='about'>
            <div className="container">
                <motion.div
                    initial={{ opacity: 0, x: -100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.8 }}
                    className="about-us-content"
                >
                    <h2>About Us</h2>
                    <p>
                        We are a digital marketing pro agency with over a decade of experience. Our team is dedicated to creating customized marketing strategies that drive results for your business. We believe in the power of data-driven decisions and aim to provide the best services to help you grow and succeed in the digital world.
                    </p>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.8 }}
                    className="about-us-image"
                >
                    <img src="./board.jpg" alt="About Us" />
                </motion.div>
            </div>
        </section>
    );
};

export default AboutUsSection;
