// Navbar.js
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './Navbar.css'; // Create this file for styling

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <motion.nav
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5 }}
            className="navbar"
        >
            <div className="navbar-logo">
                <a href="/">Covenate media solutions</a>
            </div>
            <ul className={`navbar-menu ${isOpen ? 'open' : ''}`}>
                <li><a href="#home">Home</a></li>
                <li><a href="#services">Services</a></li>
                <li><a href="#about">About Us</a></li>
                <li><a href="#faq">Faq</a></li>
                <li><a href="#price">Pricing</a></li>
                <li><a href="#Partnership">Partnership</a></li>
                <li><a href="#contact">Contact</a></li>
            </ul>
            <div className="navbar-button">
                <a href="#contact" className="btn">Get Started</a>
            </div>
            <button className="navbar-toggler" onClick={toggleMenu}>
                <span className="navbar-toggler-icon"></span>
                <span className="navbar-toggler-icon"></span>
                <span className="navbar-toggler-icon"></span>
            </button>
        </motion.nav>
    );
};

export default Navbar;
