// ClientsSection.js
import React from 'react';
import { motion } from 'framer-motion';
import './Client.css'

const ClientsSection = () => {
    const certifications = [
        { id: 1, name: 'Certification 1', logo: 'cert.png' },
        { id: 2, name: 'Certification 2', logo: 'cert.png' },
        { id: 3, name: 'Certification 3', logo: 'cert.png' },
        { id: 4, name: 'Certification 4', logo: 'cert.png' },
        { id: 5, name: 'Certification 5', logo: 'cert.png' },
        { id: 6, name: 'Certification 6', logo: 'cert.png' },
        // Add more certifications as needed
    ];

    return (
        <section className="clients-section" id='Partnership'>
            <div className="container">
                <motion.h2
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                >
                    Our Certifications
                </motion.h2>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                    className="certifications"
                >
                    {certifications.map(cert => (
                        <div key={cert.id} className="certification-card">
                            <img src={cert.logo} alt={cert.name} />
                            <p>{cert.name}</p>
                        </div>
                    ))}
                </motion.div>
            </div>
        </section>
    );
};

export default ClientsSection;
