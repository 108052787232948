// OurServices.js
import React from 'react';
import { motion } from 'framer-motion';
import './Service.css';

const services = [
        {
          "title": "Social Media Management",
          "description": "Enhance your brand's online presence with expert social media management. We create engaging content, schedule posts, and interact with your audience to build brand loyalty and increase visibility.",
          "icon": "social-media.gif"
        },
        {
          "title": "SEO Optimization",
          "description": "Improve your website’s search engine rankings with comprehensive SEO optimization services. Our strategies include keyword research, on-page optimization, and backlink building to drive organic traffic and enhance online visibility.",
          "icon": "keywords.gif"
        },
        {
          "title": "Content Marketing",
          "description": "Elevate your brand’s voice with our content marketing services. We develop high-quality, relevant content that attracts and engages your target audience, boosting brand awareness and driving conversions.",
          "icon": "content-marketing.gif"
        },
        {
          "title": "Email Marketing Campaigns",
          "description": "Maximize your outreach with targeted email marketing campaigns. We design and execute personalized email strategies that nurture leads, drive engagement, and increase conversions.",
          "icon": "email-marketing.gif"
        },
        {
          "title": "Pay-Per-Click Advertising (PPC)",
          "description": "Drive immediate traffic and increase conversions with effective pay-per-click advertising. Our PPC campaigns are optimized for maximum ROI, targeting relevant keywords and demographics to reach your ideal customers.",
          "icon": "ads.gif"
        },
        {
          "title": "Influencer Marketing",
          "description": "Leverage the power of influencer marketing to boost your brand’s credibility and reach. We connect you with influencers who resonate with your target audience to drive authentic engagement and brand advocacy.",
          "icon": "influencer.png"
        }
      ];

const OurServices = () => {
  return (
    <section className="our-services" id='services'>
      <motion.h2
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        Our Services
      </motion.h2>
      <motion.div
        className="services-container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.5 }}
      >
        {services.map((service, index) => (
          <motion.div
            className="service-card"
            key={index}
            whileHover={{ scale: 1.05 }}
          >
            <img src={service.icon} alt={`${service.title} Icon`} className="service-icon" />
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </motion.div>
        ))}
      </motion.div>
    </section>
  );
};

export default OurServices;
