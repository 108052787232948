// NewsletterSection.js
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './Newsletter.css';

const NewsletterSection = () => {
    const [email, setEmail] = useState('');

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission (e.g., send data to server)
        console.log('Email submitted:', email);
        setEmail(''); // Reset the input field
    };

    return (
        <section className="newsletter-section">
            <div className="container">
                <motion.h2
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                >
                    Subscribe to our Newsletter
                </motion.h2>
                <motion.form
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                    onSubmit={handleSubmit}
                    className="newsletter-form"
                >
                    <input
                        type="email"
                        placeholder="Enter your email address"
                        value={email}
                        onChange={handleChange}
                        required
                    />
                    <button type="submit">Subscribe</button>
                </motion.form>
            </div>
        </section>
    );
};

export default NewsletterSection;
