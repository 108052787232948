// Footer.js
import React from 'react';
import './Footer.css'; // Ensure this file contains the relevant styles
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram  } from 'react-icons/fa';


const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer-content">
                    <div className="footer-section">
                        <h3>MyWebsite</h3>
                        <p>Your go-to solution for professional marketing services.</p>
                    </div>
                    <div className="footer-section">
                        <h3>Quick Links</h3>
                        <ul>
                            <li><a href="#home">Home</a></li>
                            <li><a href="#about">About Us</a></li>
                            <li><a href="#services">Services</a></li>
                            <li><a href="#price">Packages</a></li>
                            <li><a href="#faq">Faq</a></li>
                            <li><a href="#contact">Contact</a></li>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <h3>Contact Us</h3>
                        <ul>
                            <li>Email: info@thecovenantmedia.com</li>
                            <li>Phone: +1 272 206 0453</li>
                            <li>Address: 177 Wemmerpan St, Johannesburg, South Africa</li>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <h3>Follow Us</h3>
                        <div className="social-icons">
                            <a href="#linl"><i className="fab fa-facebook-f"><FaFacebookF /></i></a>
                            <a href="#link"><i className="fab fa-twitter"><FaTwitter /></i></a>
                            <a href="#link"><i className="fab fa-linkedin-in"><FaLinkedinIn /></i></a>
                            <a href="#link"><i className="fab fa-instagram"><FaInstagram /></i></a>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>&copy; {new Date().getFullYear()} MyWebsite. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
