// HeroSection.js
import React from 'react';
import { motion } from 'framer-motion';
import { TypeAnimation } from 'react-type-animation';
import './Banner.css';

const HeroSection = () => {
  return (
    <section className="hero-section" id="home">
      <motion.div
        className="hero-content"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h1>
          <TypeAnimation
            sequence={[
              'Professional marketing copy for anyone',
              2000,
              'We bring more clients',
              2000,
              'Social media marketing',
              2000,
              'Grow your business with us',
              2000,
            ]}
            wrapper="span"
            speed={40}
            style={{ fontSize: '48px', display: 'inline-block' }}
            repeat={Infinity}
          />
        </h1>
        <p>We help clients achieve strategic vision with digital marketing services.</p>
        <a href="#services" className="btn hero-btn">Learn More</a>
      </motion.div>
      <motion.div
        className="hero-image-container"
        initial={{ x: 100 }}
        animate={{ x: 0 }}
        transition={{ duration: 1 }}
      >
        <div className="hero-image-wrapper">
          <img src="hero.png" alt="Hero" className="hero-image" />
        </div>
        <div className="hero-text">
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1, duration: 1 }}
          >
            #MarketingPro
          </motion.span>
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.5, duration: 1 }}
          >
            #DigitalGrowth
          </motion.span>
        </div>
      </motion.div>
    </section>
  );
};

export default HeroSection;
