import React from 'react';
import { motion } from 'framer-motion';
import './Contact.css';

const ContactFormSection = () => {
    return (
        <section className="contact-form-section" id='contact'>
            <div className="container">
                <motion.h2
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                >
                    Contact Us
                </motion.h2>
                <motion.form
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                    action="https://formsubmit.co/info@thecovenantmedia.com"
                    method="POST"
                    className="contact-form"
                >
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="subject">Subject</label>
                        <input
                            type="text"
                            id="subject"
                            name="subject"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea
                            id="message"
                            name="message"
                        ></textarea>
                    </div>
                    <button type="submit">Send Message</button>
                </motion.form>
                   
                   
                
            </div>

            
        </section>
    );
};

export default ContactFormSection;
